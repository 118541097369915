import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Switch, Popover } from "@headlessui/react";
import { BeakerIcon, ShoppingCartIcon } from "@heroicons/react/solid";

export default function Example(props) {
  const [open, setOpen] = useState(true);
  let [isOpen, setIsOpen] = useState(true);
  const [enabled, setEnabled] = useState(false);
  var product = props.item;

  return (
    <>
      <div class="m-10">
        <>
          <h1 className="mb-10 text-lg font-bold text-slate-700">
            Terms and Conditions for LilalyPh
          </h1>
          <h1 className="text-sm font-bold text-slate-700">Introduction</h1>
          <p>
            These Website Standard Terms and Conditions written on this webpage
            shall manage your use of our website, https://lilalyph.com
            accessible at https://lilalyph.com.
          </p>

          <p class="... font-sans">
            These Terms will be applied fully and affect to your use of this
            Website. By using this Website, you agreed to accept all terms and
            conditions written in here. You must not use this Website if you
            disagree with any of these Website Standard Terms and Conditions.
            These Terms and Conditions have been generated with the help of the{" "}
            <a href="https://www.termsandcondiitionssample.com">
              Terms And Conditiions Sample Generator
            </a>
            .
          </p>

          <p class="... font-sans">
            Minors or people below 18 years old are not allowed to use this
            Website.
          </p>

          <h1 className="mb-5 mt-10 text-sm font-bold text-slate-700">
            Intellectual Property Rights
          </h1>

          <p class="... font-sans">
            Other than the content you own, under these Terms, LilalyPh and/or
            its licensors own all the intellectual property rights and materials
            contained in this Website.
          </p>

          <p>
            You are granted limited license only for purposes of viewing the
            material contained on this Website.
          </p>

          <h1 className="mb-5 mt-10 text-sm font-bold text-slate-700">
            Service Terms
          </h1>
          <p>
            LilalyPh provides event, wedding, and corporate-related services,
            subject to availability and acceptance of the customer's
            requirements. All bookings are subject to confirmation and
            availability. LilalyPh reserves the right to refuse service to
            anyone for any reason at any time.
          </p>

          <h1 className="mb-5 mt-10 text-sm font-bold text-slate-700">
            Rebates
          </h1>
          <p>
            Rebates may be offered at the discretion of LilalyPh and are subject
            to specific terms and conditions.
          </p>

          <p>Rebates are non-transferable and have no cash value.</p>
          <p>
            LilalyPh reserves the right to modify or discontinue the rebate
            program at any time without prior notice.
          </p>

          <h1 className="mb-5 mt-10 text-sm font-bold text-slate-700">
            Referral Program
          </h1>
          <p>
            LilalyPh may offer a referral program where customers can refer
            others to our services and earn rewards.
          </p>

          <p>
            Referral rewards are subject to specific terms and conditions and
            may vary based on promotions.
          </p>
          <p>
            LilalyPh reserves the right to modify or terminate the referral
            program at any time without prior notice.
          </p>

          <h1 className="mb-5 mt-10 text-sm font-bold text-slate-700">
            Restrictions
          </h1>

          <p>You are specifically restricted from all of the following:</p>

          <ul>
            <li>publishing any Website material in any other media;</li>
            <li>
              selling, sublicensing and/or otherwise commercializing any Website
              material;
            </li>
            <li>publicly performing and/or showing any Website material;</li>
            <li>
              using this Website in any way that is or may be damaging to this
              Website;
            </li>
            <li>
              using this Website in any way that impacts user access to this
              Website;
            </li>
            <li>
              using this Website contrary to applicable laws and regulations, or
              in any way may cause harm to the Website, or to any person or
              business entity;
            </li>
            <li>
              engaging in any data mining, data harvesting, data extracting or
              any other similar activity in relation to this Website;
            </li>
            <li>
              using this Website to engage in any advertising or marketing.
            </li>
          </ul>

          <p>
            Certain areas of this Website are restricted from being access by
            you and LilalyPh may further restrict access by you to any areas of
            this Website, at any time, in absolute discretion. Any user ID and
            password you may have for this Website are confidential and you must
            maintain confidentiality as well.
          </p>

          <h1 className="mb-5 mt-10 text-sm font-bold text-slate-700">
            Your Content
          </h1>

          <p>
            In these Website Standard Terms and Conditions, "Your Content" shall
            mean any audio, video text, images or other material you choose to
            display on this Website. By displaying Your Content, you grant
            LilalyPh a non-exclusive, worldwide irrevocable, sub licensable
            license to use, reproduce, adapt, publish, translate and distribute
            it in any and all media.
          </p>

          <p>
            Your Content must be your own and must not be invading any
            third-party’s rights. LilalyPh reserves the right to remove any of
            Your Content from this Website at any time without notice.
          </p>

          <h1 className="mb-5 mt-10 text-sm font-bold text-slate-700">
            Your Privacy
          </h1>

          <p>Please read Privacy Policy.</p>

          <h1 className="mb-5 mt-10 text-sm font-bold text-slate-700">
            No warranties
          </h1>

          <p>
            This Website is provided "as is," with all faults, and LilalyPh
            express no representations or warranties, of any kind related to
            this Website or the materials contained on this Website. Also,
            nothing contained on this Website shall be interpreted as advising
            you.
          </p>

          <h1 className="mb-5 mt-10 text-sm font-bold text-slate-700">
            Limitation of liability
          </h1>

          <p>
            In no event shall LilalyPh, nor any of its officers, directors and
            employees, shall be held liable for anything arising out of or in
            any way connected with your use of this Website whether such
            liability is under contract.  LilalyPh, including its officers,
            directors and employees shall not be held liable for any indirect,
            consequential or special liability arising out of or in any way
            related to your use of this Website.
          </p>

          <h1 className="mb-5 mt-10 text-sm font-bold text-slate-700">
            Indemnification
          </h1>

          <p>
            You hereby indemnify to the fullest extent LilalyPh from and against
            any and/or all liabilities, costs, demands, causes of action,
            damages and expenses arising in any way related to your breach of
            any of the provisions of these Terms.
          </p>

          <h1 className="mb-5 mt-10 text-sm font-bold text-slate-700">
            Severability
          </h1>

          <p>
            If any provision of these Terms is found to be invalid under any
            applicable law, such provisions shall be deleted without affecting
            the remaining provisions herein.
          </p>

          <h1 className="mb-5 mt-10 text-sm font-bold text-slate-700">
            Variation of Terms
          </h1>

          <p>
            LilalyPh is permitted to revise these Terms at any time as it sees
            fit, and by using this Website you are expected to review these
            Terms on a regular basis.
          </p>

          <h1 className="mb-5 mt-10 text-sm font-bold text-slate-700">
            Assignment
          </h1>

          <p>
            The LilalyPh is allowed to assign, transfer, and subcontract its
            rights and/or obligations under these Terms without any
            notification. However, you are not allowed to assign, transfer, or
            subcontract any of your rights and/or obligations under these Terms.
          </p>

          <h1 className="mb-5 mt-10 text-sm font-bold text-slate-700">
            Entire Agreement
          </h1>

          <p>
            These Terms constitute the entire agreement between LilalyPh and you
            in relation to your use of this Website, and supersede all prior
            agreements and understandings.
          </p>

          <h1>Governing Law & Jurisdiction</h1>

          <p>
            These Terms will be governed by and interpreted in accordance with
            the laws of the State of ph, and you submit to the non-exclusive
            jurisdiction of the state and federal courts located in ph for the
            resolution of any disputes.
          </p>
        </>
      </div>
    </>
  );
}
