/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { useLocation, withRouter } from "react-router-dom";

const API = "https://www.smestoreph.com/signup";
const navigation = [
  { name: "Home", href: "#" },
  { name: "Services", href: "" },
  {
    name: "Privacy Policy",
    href: "https://partners.lilalyph.com/PrivacyPolicy",
  },
  {
    name: "Terms & Conditon",
    href: "https://partners.lilalyph.com/TermsAndCondition",
  },
];

export default function Example(props) {
  const [contentType, setContentView] = useState("Landing");
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [location, setLocation] = useState("");
  const [service, setService] = useState("");
  const [Others, setServiceOthers] = useState("");
  const [submit, didSubmit] = useState(false);

  const usePathname = () => {
    const location = useLocation();
    console.log(location);
    return location;
  };

  useEffect(() => {
    console.log("Landing", props.type);
    var type = props.type === "/apply=true" ? "Form" : "Landing";
    setContentView(type);
  }, []);

  const submitService = async () => {
    var fullName = `${lastname}, ${name}`;
    const data = await fetch(API, {
      method: "POST",
      headers: {
        Authorization: "Basic",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        applicant: "lilalyphPH",
        mobile: mobile,
        businessName: fullName,
        location: location,
        date: Date(),
        service: service,
        others: Others,
      }),
    });
    return data;
  };

  const submitFormDetails = (e) => {
    didSubmit(true);
    submitService().then((data) => {
      setContentView("Success");
      setMobile("");
      setName("");
      setLastName("");
      setLocation("");
      setService("");
      setServiceOthers("");
      didSubmit(false);
    });
  };
  const LandingForm = () => {
    return (
      <main className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12  sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div className="sm:text-center lg:text-left ">
          <h1 className="my-7 text-4xl font-extrabold  tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            {/* <span className="block xl:inline">Data to enrich your</span>{' '} */}
            <span className="text-dark-900 block  text-8xl xl:inline ">
              Manage your Bookings seamlessly.
            </span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
            Extend your visibility with our seamless booking event transaction,
            we are in BETA launch.
          </p>
          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              <a
                href="javascrit:(0);"
                onClick={() => setContentView("Form")}
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-gray-900 px-8 py-3 text-base font-medium text-white hover:bg-gray-700 md:px-10 md:py-4 md:text-lg"
              >
                Apply here
              </a>
            </div>
            {/* <div className="mt-3 sm:mt-0 sm:ml-3">
               <a

              href="javascrit:(0);"
              onClick={()=>setContentView('Form')}
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-black bg-white hover:text-gray-300 md:py-4 md:text-lg md:px-10"
            >
            Be our Partner
            </a>
          </div> */}
          </div>
        </div>
      </main>
    );
  };

  const FieldForm = () => {
    return (
      <>
        <div class="w-full max-w-lg">
          <div class="-mx-3 mb-6 flex flex-wrap">
            <div class="mb-6  w-full px-3 md:mb-0">
              <label
                class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
                for="grid-first-name"
              >
                Business Name
              </label>
              <input
                value={name}
                onChange={(e) => setName(e.nativeEvent.target.value)}
                class="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:bg-white focus:outline-none"
                id="grid-first-name"
                type="text"
                placeholder="Jane"
              />
              <p class="text-xs italic text-gray-600">
                Please fill out this field.
              </p>
            </div>
            {/* <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
        Last Name
      </label>
      <input value={lastname} onChange={(e)=>setLastName(e.nativeEvent.target.value)} class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Doe"/>
    </div> */}
          </div>
          <div class="-mx-3 mb-6 flex flex-wrap">
            <div class="w-1/2 px-3">
              <label
                class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
                for="grid-password"
              >
                Business Mobile Number
              </label>
              <input
                value={mobile}
                onChange={(e) => setMobile(e.nativeEvent.target.value)}
                class="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
                id="grid-password"
                type="text"
                placeholder="09** **** ***"
              />
              <p class="text-xs italic text-gray-600">Primary number</p>
            </div>
          </div>
          <div class="-mx-2 mb-2 flex flex-wrap">
            <div class="mb-6 w-full px-2 md:mb-0 md:w-1/2">
              <label
                class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
                for="grid-city"
              >
                Covered Location
              </label>
              <input
                value={location}
                onChange={(e) => setLocation(e.nativeEvent.target.value)}
                class="block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
                id="grid-city"
                type="text"
                placeholder="Cagayan De Oro City"
              />
            </div>
            <div class="mb-6 w-full px-3 md:mb-0 md:w-1/3">
              <label
                class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
                for="grid-state"
              >
                Service Type
              </label>
              <div class="relative">
                <select
                  onChange={(e) => setService(e.nativeEvent.target.value)}
                  class="block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 pr-8 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
                  id="grid-state"
                >
                  <option>Wedding Coordinator</option>
                  <option>Hosting</option>
                  <option>Photographer</option>
                  <option>Video Grapher</option>
                  <option>Florist</option>
                  <option>Bridal Gown / Suit Rental</option>
                  <option>Patisserie</option>
                  <option>Lights & sounds</option>
                  <option>Makeup Artist</option>
                  <option>Venue</option>
                  <option>Rental Service</option>
                  <option>Others</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    class="h-4 w-4 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        {service === "Others" ? (
          <div class="-mx-3 mb-6 flex flex-wrap">
            <div class="w-1/2 px-3">
              <label
                class="mb-2 mt-5 block text-xs font-bold uppercase tracking-wide text-gray-700"
                for="grid-password"
              >
                Service (others)
              </label>
              <input
                value={Others}
                onChange={(e) => setServiceOthers(e.nativeEvent.target.value)}
                class="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
                id="grid-password"
                type="text"
                placeholder="Type your service category"
              />
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const SucccessForm = () => {
    return (
      <main className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12  sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div className="sm:text-center lg:text-left ">
          <h1 className="my-7 text-4xl font-extrabold  tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            <span className="text-dark-900 block  text-8xl xl:inline ">
              Thank you!
            </span>
          </h1>

          <p className="mt-10 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
            Thank you for having interest with us! we will contact you soon.
          </p>
          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              <a
                href="javascrit:(0);"
                onClick={() => setContentView("Landing")}
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-black hover:text-gray-300 md:px-10 md:py-4 md:text-lg"
              >
                Back to Home
              </a>
            </div>
            <div className="mt-3 sm:ml-3 sm:mt-0"></div>
          </div>
        </div>
      </main>
    );
  };

  const ContactForm = () => {
    return (
      <main className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12  sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div className="sm:text-center lg:text-left ">
          <h1 className="my-7 text-4xl font-extrabold  tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            <span className="text-dark-900 block  text-8xl xl:inline ">
              Application
            </span>
          </h1>
          <p className="mb-10 mt-10 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
            Provide us your business details
          </p>
          {FieldForm()}

          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              <a
                href="javascrit:(0);"
                onClick={() => setContentView("Landing")}
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-black hover:text-gray-300 md:px-10 md:py-4 md:text-lg"
              >
                Back
              </a>
            </div>
            <div className="mt-3 sm:ml-3 sm:mt-0">
              <a
                href="javascrit:(0);"
                onClick={submitFormDetails}
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-gray-900 px-8 py-3 text-base font-medium text-white hover:bg-gray-700 md:px-10 md:py-4 md:text-lg"
              >
                {submit ? (
                  <svg
                    class="... mr-3 h-5 w-5 animate-spin bg-white"
                    viewBox="0 0 24 24"
                  />
                ) : null}
                Submit Form
              </a>
            </div>
          </div>
        </div>
      </main>
    );
  };
  return (
    <div className="relative overflow-hidden bg-white">
      <div className="mx-auto h-screen max-w-7xl">
        <div className="relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
          <svg
            className="absolute inset-y-0  right-0 hidden h-screen w-48 translate-x-1/2 transform text-white lg:block"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <Popover>
            <div className="relative px-4 pt-6 sm:px-6 lg:px-8">
              <nav
                className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                aria-label="Global"
              >
                <div className="flex flex-shrink-0 flex-grow items-center lg:flex-grow-0">
                  <div className="flex w-full items-center justify-between md:w-auto">
                    {/* <a href="https://www.lilalyph.com/">
                      <span className="sr-only"></span>
                      <img
                        className="h-10 w-auto sm:h-10"
                        src="https://localflowershop.sgp1.digitaloceanspaces.com/product/1630747499716-lilalyph.949e29a5.png "
                      />
                    </a> */}
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open main menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:ml-10 md:block md:space-x-8 md:pr-4">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="font-medium text-gray-500 hover:text-gray-900"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden"
              >
                <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                  <div className="flex items-center justify-between px-5 pt-4">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src="https://localflowershop.sgp1.digitaloceanspaces.com/product/1630747499716-lilalyph.949e29a5.png"
                        alt=""
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close main menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="space-y-1 px-2 pb-3 pt-2">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          {contentType === "Landing"
            ? LandingForm()
            : contentType === "Success"
              ? SucccessForm()
              : ContactForm()}
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
          src={
            contentType === "Success"
              ? "https://i.pinimg.com/originals/d7/45/2f/d7452fd3a92e09d4e4d7024c2c1ed567.jpg"
              : "https://images.unsplash.com/photo-1612893201883-c3f1fb4265d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1300&q=80"
          }
          alt=""
        />
      </div>
    </div>
  );
}
