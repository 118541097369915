import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Switch, Popover } from "@headlessui/react";
import { BeakerIcon, ShoppingCartIcon } from "@heroicons/react/solid";

import LandingHeader from "./component/LandingHeader";
import { axios } from "./Network";

import ContentLoader from "react-content-loader";
var id = process.env.REACT_APP_STORE_ID;

const MyLoader = (props) => (
  <ContentLoader viewBox="0 0 400 31" height={200} width={"auto"} {...props}>
    <rect height="5.5" rx="1" ry="1" width="340" x="31" y="5" />
    <rect height="5.5" rx="1" ry="1" width="340" x="31" y="15" />
    <circle cx="388" cy="12" r="12" />
    <rect height="24" rx="0" ry="0" width="24" x="0" y="0" />
  </ContentLoader>
);
export default function Example(props) {
  const [open, setOpen] = useState(true);
  let [isOpen, setIsOpen] = useState(true);
  const [enabled, setEnabled] = useState(false);
  var product = props.item;
  let [didLoad, didLoadStore] = useState(false);
  let [store, setStoreDetails] = useState(null);

  useEffect(() => {
    let parameter = {
      id: id,
      equalTo: true,
      storeOwner: id,
      cType: "Product",
      cName: "Category",
    };
    axios
      .post("/details/Store", parameter)
      .then((response) => {
        setStoreDetails(response.data.results[0]);
        didLoadStore(true);
      })
      .catch(function (error) {
        alert("Something went wrong, please try again");
      });
  }, []);
  return (
    <>
      <div class="m-10">
        <img
        //                       className="h-20   lg:text-center-1 w-auto sm:h-30 sticky margin: 20rem my-20"
        //                       src={store === null  ? '' : store.storeLogo }
        />
        <h6 className="lg:text-left-1 margin: 20rem mb-90 sticky my-20 content-center text-sm font-bold uppercase tracking-tight text-gray-900 sm:text-center ">
          Privacy Policy
        </h6>
        <p className="mb-2 mt-3 text-base text-gray-500">
          We built the LilalyPh as a Commercial app. This SERVICE is provided by
          us and is intended for use as is. This page is used to inform visitors
          regarding my policies with the collection, use, and disclosure of
          Personal Information if anyone decided to use my Service. If you
          choose to use my Service, then you agree to the collection and use of
          information in relation to this policy. The Personal Information that
          I collect is used for providing and improving the Service. I will not
          use or share your information with anyone except as described in this
          Privacy Policy. The terms used in this Privacy Policy have the same
          meanings as in our Terms and Conditions, which is accessible
          atLilalyPh unless otherwise defined in this Privacy Policy.
        </p>
        <h5 className="mt-5 text-sm font-bold uppercase tracking-tight text-gray-900  ">
          Information Collection and Use
        </h5>
        <ul class="ml-5 list-disc">
          <li>
            {" "}
            <p className="mb-2 mt-3 text-base text-gray-500">
              {" "}
              For a better experience, while using our Service, I may require
              you to provide us with certain personally identifiable
              information, including but not limited to Basic Information,
              Email, Facebook profile image, Facebook ID, Facebook User name,
              Facebook Public Name, mobile number. The information that I
              request will be retained on your device and is not collected by me
              in any way. The app does use third party services that may collect
              information used to identify you. Link to privacy policy of third
              party service providers used by the app
            </p>
          </li>
          <li>
            {" "}
            <p className="mb-2 mt-3 text-base text-gray-500">
              {" "}
              Google Play Services
            </p>
          </li>
        </ul>
        <h5 className="mt-5 text-sm font-bold uppercase tracking-tight text-gray-900 ">
          Log Data
        </h5>
        <p className="mb-2 mt-3 text-base text-gray-500">
          I want to inform you that whenever you use my Service, in a case of an
          error in the app I collect data and information (through third party
          products) on your phone called Log Data. This Log Data may include
          information such as your device Internet Protocol (“IP”) address,
          device name, operating system version, the configuration of the app
          when utilizing my Service, the time and date of your use of the
          Service, and other statistics.
        </p>
        <h5 className="mt-5 text-sm font-bold uppercase tracking-tight text-gray-900 ">
          Service Providers
        </h5>
        <p className="mb-2 mt-3 text-base text-gray-500">
          I may employ third-party companies and individuals due to the
          following reasons:
        </p>
        <ul class="ml-5 list-disc">
          <li>
            {" "}
            <p className="mb-2 mt-3 text-base text-gray-500">
              {" "}
              To facilitate our Service
            </p>
          </li>
          <li>
            {" "}
            <p className="mb-2 mt-3 text-base text-gray-500">
              {" "}
              To provide the Service on our behalf
            </p>
          </li>
          <li>
            {" "}
            <p className="mb-2 mt-3 text-base text-gray-500">
              {" "}
              To perform Service-related services; or
            </p>
          </li>
          <li>
            {" "}
            <p className="mb-2 mt-3 text-base text-gray-500">
              {" "}
              To assist us in analyzing how our Service is used.
            </p>
          </li>
          <li>
            {" "}
            <p className="mb-2 mt-3 text-base text-gray-500">
              {" "}
              want to inform users of this Service that these third parties have
              access to your Personal Information. The reason is to perform the
              tasks assigned to them on our behalf. However, they are obligated
              not to disclose or use the information for any other purpose.
            </p>
          </li>
        </ul>
        <h5 className="mt-5 text-sm font-bold uppercase tracking-tight text-gray-900 ">
          Security
        </h5>
        <p className="mb-2 mt-3 text-base text-gray-500">
          I value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and I cannot
          guarantee its absolute security.
        </p>
        <h5 className="mt-5 text-sm font-bold uppercase tracking-tight text-gray-900 ">
          Links to Other Sites
        </h5>
        <p className="mb-2 mt-3 text-base text-gray-500">
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by me. Therefore, I strongly advise
          you to review the Privacy Policy of these websites. I have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </p>
        <h5 className="mt-5 text-sm font-bold uppercase tracking-tight text-gray-900 ">
          Children’s Privacy
        </h5>
        <p className="mb-2 mt-3 text-base text-gray-500">
          These Services do not address anyone under the age of 13. I do not
          knowingly collect personally identifiable information from children
          under 13. In the case I discover that a child under 13 has provided me
          with personal information, I immediately delete this from our servers.
          If you are a parent or guardian and you are aware that your child has
          provided us with personal information, please contact me so that I
          will be able to do necessary actions.
        </p>
        <h5 className="mt-5  text-sm font-bold uppercase tracking-tight text-gray-900 ">
          Changes to This Privacy Policy
        </h5>
        <p className="mb-2 mt-3 text-base text-gray-500">
          I may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. I will
          notify you of any changes by posting the new Privacy Policy on this
          page. These changes are effective immediately after they are posted on
          this page.
        </p>
        <h5 className="mt-5  text-sm  font-bold  tracking-tight text-gray-900 ">
          Contact Us
        </h5>
        <p className="mb-2 mt-3 text-base text-gray-500">
          If you have any questions or suggestions about my Privacy Policy, do
          not hesitate to contact me at support@lilalyph.com
        </p>
      </div>
    </>
  );
}
